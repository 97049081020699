'use client';

import React, { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import Cookies from 'js-cookie';
import analytics from 'mixpanel-browser';
import { useSearchParams } from 'next/navigation';

import type { CommunityMember, User } from '@zealy/queries';
import { isUserConnected, useAuthenticatedUser, useCommunity } from '@zealy/queries';
import { getSubdomain, roleIsAtLeast } from '@zealy/utils';

import type { ICommunity } from '#types';
import { envConfig } from '#app/config';

import { useCookiePreferences } from './CookiePreferences';
import { communitiesFlag } from './FeatureFlags/communitiesFlag';

const AnalyticsContext = React.createContext<{
  analytics: typeof analytics;
  identify?: (user: User | CommunityMember, community?: ICommunity) => void;
}>({
  analytics,
});

analytics.init(envConfig.mixpanel.key, {
  debug: envConfig.env !== 'production',
  ignore_dnt: envConfig.env !== 'production',
});

export const AnalyticsProvider = ({ children }: { children: React.ReactNode }) => {
  const { data: userData, isFetched: isUserFetched } = useAuthenticatedUser();
  const { data: community, isFetched: isCommunityFetched } = useCommunity();

  const intercom = useIntercom();
  const search = useSearchParams();

  const { preferences } = useCookiePreferences();

  const isSupportRequest = search?.get('support') === 'true';
  const hasOnGoingSupportRequest = Cookies.get('support') === 'true';

  const handleIntercom = (user?: User | CommunityMember, community?: ICommunity) => {
    if (envConfig.appEnv !== 'production') return;

    if (!user) {
      intercom?.boot({
        hideDefaultLauncher: !isSupportRequest && !hasOnGoingSupportRequest,
      });
      return;
    }

    const accounts = 'accounts' in user && user.accounts ? user.accounts : [];
    const intercomUserIdHash =
      'intercomUserIdHash' in user && user.intercomUserIdHash ? user.intercomUserIdHash : undefined;

    intercom?.boot({
      hideDefaultLauncher: !isSupportRequest && !hasOnGoingSupportRequest,
      name: user.name ?? undefined,
      email: accounts.find(a => a.accountType === 'email')?.authentification,
      userId: user.id,
      userHash: intercomUserIdHash,
      ...(user.avatar && {
        avatar: {
          type: 'avatar',
          imageUrl: user.avatar,
        },
      }),
      customAttributes: {
        twitterUsername: user.twitterUsername,
        discordHandle: user.discordHandle,
        ...(community && {
          community: community.name,
          communityFlagged: community.flag,
          communityId: community.id,
          communityUrl: window.location.href.split('?')[0],
          role: 'role' in user ? user.role : undefined,
          profileUrl: `${envConfig.appUrl}/cw/${community.subdomain}/users/${user.id}`,
          ...('role' in user &&
            user.role === 'admin' && {
              customerId: community.customerId,
              planId: community.planId,
            }),
        }),
      },
    });
  };

  const identify = (user: User | CommunityMember, community?: ICommunity) => {
    const accounts = 'accounts' in user && user.accounts ? user.accounts : [];

    if (!user?.id) return;

    const email =
      accounts.find(({ accountType }) => accountType === 'email')?.authentification ?? '';

    const identifyData = {
      name: user.name,
      community: community?.name ?? '',
      communityId: community?.id ?? '',
      email,
      discord: user.discordHandle,
      twitter: user.twitterUsername,
      registration_date: user.createdAt,
      ...('xp' in user && {
        xp: Number(user.xp),
        rank: Number(user.rank),
        level: Number(user.level),
        invites: Number(user.invites),
        role: user.role ?? '',
      }),
    };

    if (preferences?.analytics) {
      // Identify the user with Mixpanel - only if reviewer or higher
      if (roleIsAtLeast('role' in user ? user?.role : undefined, 'reviewer')) {
        analytics.identify(user.id);
        analytics.people.set(user.id, {
          ...identifyData,
          isPremium: 'role' in user && !!community?.planId && roleIsAtLeast(user.role, 'reviewer'),
        });
      }
    }
  };

  useEffect(() => {
    const isRootSubdomain = getSubdomain() === 'root';
    const shouldIdentifyUser = userData && isUserFetched && (isCommunityFetched || isRootSubdomain);

    if (shouldIdentifyUser) {
      handleIntercom(userData, community);
      identify(userData, community);
    } else if (isRootSubdomain) {
      handleIntercom({} as any, community);
    }

    if (!community || !isCommunityFetched) return;

    const communityTraits = {
      type: 'community',
      communityId: community.id,
      totalMembers: community.totalMembers || 0,
      subdomain: community.subdomain,
      v2: !!community.v2,
    };

    const cachedCommunityTraits = communitiesFlag.getAllTraits();

    const isUserLoaded = isUserFetched || !isUserConnected();

    const isCommunityIdentified =
      communitiesFlag.identity && communitiesFlag.identity === `community-${community.id}`;

    const isCommunityUpToDate = !hasTraitsChanged(communityTraits, cachedCommunityTraits);

    const shouldIdentifyCommunity =
      (!isCommunityIdentified || !isCommunityUpToDate) && isUserLoaded;

    if (shouldIdentifyCommunity) {
      communitiesFlag.identify(`community-${community.id}`, communityTraits);
    }
  }, [userData, community, isUserFetched, isCommunityFetched]);

  useEffect(() => {
    if (isSupportRequest) {
      if (!hasOnGoingSupportRequest) Cookies.set('support', 'true', { expires: 3 });
      intercom.show();
    }
  }, [isSupportRequest]);

  useEffect(() => {
    const referrer = search?.get('source') ?? document.referrer;
    if (referrer) {
      Cookies.set('referrer-url', referrer, {
        expires: 1,
        domain: `${window.location.hostname}`,
        secure: true,
      });
    }
  }, []);

  return (
    <AnalyticsContext.Provider value={{ analytics, identify }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

// Create an analytics hook that we can use with other components.
export const useEventTracker = () => {
  const result = React.useContext(AnalyticsContext);
  if (!result) {
    throw new Error('Context used outside of its Provider!');
  }
  return result;
};

const hasTraitsChanged = (
  newTraits: Record<string, string | null | undefined | boolean | number>,
  oldTraits: Record<string, string | null | undefined | boolean | number>,
) => {
  if (!oldTraits) {
    return true;
  }

  const newTraitsWithoutNulls = Object.keys(newTraits).reduce((acc, key) => {
    if (newTraits[key] !== null) {
      return { ...acc, [key]: newTraits[key] };
    }
    return acc;
  }, {});

  return Object.keys(newTraitsWithoutNulls).some(
    // The cached keys are returned in lowercase. Probably a bug in the library.
    key => newTraits[key] !== oldTraits[key.toLowerCase()],
  );
};
